import React, { useState, useEffect } from "react";
import { SectionController } from "./../../utils/Controller/SectionsController";
import { SimpleSelect } from "./../../components/CustomInput/Selector";
import { Helpers } from "./../../core/helpers";
export function PlanSelector({ value, ...rest }) {
  const [options, setOptions] = useState([]);
  let val = "";
  const loadSections = () => {
    const fetch = new SectionController();
    fetch.get().then(({ data }) => {
      setOptions(data.content);
    });
  };
  useEffect(loadSections, []);
  let filtered = new Helpers().searchByKey(options, "id", value)[0];
  if (filtered) {
    val = { label: filtered.plan, value };
  }
  return (
    <SimpleSelect
      value={val}
      {...rest}
      options={options.map((item) => ({ label: item.plan, value: item.id }))}
    />
  );
}
