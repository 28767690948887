import React, { useEffect, useState } from "react";
import { GridContainer, GridItem } from "./../../components/Grid/Grid";
import { NavLink, useParams } from "react-router-dom";
import "./scss/users.scss";
import { UsersController } from "./../../utils/Controller/UsersController";
import { FaButton } from "../../components/CustomButtons/FaButton";
import { useCState } from "./../../utils/hooks/simpleHooks";
import { ProfileImage } from "./UsersForm";
import { Account } from "./Account";
import { InfiniteList, LocalSearchLists } from "../../localComponents/Lists";
import { List } from "./../../localComponents/Lists";
import { UserPreview } from "./UserPreview";
import { LazyPostsByUser } from "./../Posts/PostsContainer";
import { SimpleSearchInput } from "../../components/CustomInput/SearchInput";
import { UserChart } from "../Charts/AnaliticsChart";

/**
 *
 * @param {img,name,plan,profile,id} param0
 */

export function UserList({ page = 1, load = true, onLoad, loadBy }) {
  return (
    <List
      page={page}
      onLoad={onLoad}
      load={load}
      by={{
        getter: () => {
          return loadBy();
        },
      }}
      format={({
        id,
        picture_path,
        name,
        paterno,
        materno,
        plan,
        record_status,
        username,
        ...rest
      }) => (
        <UserPreview
          id={id}
          key={"user-" + id}
          img={`https://${picture_path}`}
          name={`${name} ${paterno} ${materno}`}
          plan={plan}
          status={record_status}
          username={username}
          {...rest}
        />
      )}
    />
  );
}
export function AddUserButton() {
  return (
    <NavLink to="/user/register">
      <FaButton icon={"plus"}>Crear usuario</FaButton>
    </NavLink>
  );
}
export function LazyUsersList() {
  return (
    <InfiniteList
      defaultList={UserList}
      loadBy={() => {
        const fetch = new UsersController();
        return fetch.get();
      }}
    />
  );
}
export function UsersListByStatus() {
  const { id } = useParams();

  return (
    <LocalSearchLists
      by={() => {
        const fetch = new UsersController();
        return fetch.getUsersByStatus(id);
      }}
      format={(item) => (
        <GridItem key={"user-" + item.id}>
          <UserPreview
            id={item.id}
            perfil={item.perfil}
            img={`https://${item.picture_path}`}
            name={`${item.name} ${item.paterno} ${item.materno}`}
            plan={item.plan}
            status={item.record_status}
            username={item.username}
          />
        </GridItem>
      )}
    />
  );
}
export function UserProfile({ id }) {
  const [state, setState] = useCState({});
  const [loading, setLoading] = useState(0);
  const loadUser = () => {
    const fetch = new UsersController();
    setLoading(1);
    fetch.show(id).then((response) => {
      setState(response.data.content[0]);
      setLoading(0);
    });
  };
  useEffect(loadUser, [id]);
  if (loading) return "Cargando...";
  return (
    <GridContainer>
      <GridItem xs={12}>
        <ProfileImage
          id={id}
          src={state.picture_path}
          onChange={(target) => {
            const fetch = new UsersController();
            fetch.update(id, { imagen: target.files[0] });
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <Account id={id} />
      </GridItem>
    </GridContainer>
  );
}
export function SingleUserView() {
  const { id } = useParams();
  return (
    <GridContainer>
      <GridItem xs={12} md={8}>
        <UserChart userID={id} />

        <LazyPostsByUser id={id} />
      </GridItem>
      <GridItem md={4}>
        <UserProfile id={id} />
      </GridItem>
    </GridContainer>
  );
}
export function UsersListByPlan() {
  const { id } = useParams();
  const [search, setSearch] = useState("");

  return (
    <>
      <SimpleSearchInput
        onSearch={(value) => {
          console.log(value);
          setSearch(value);
        }}
        onChange={({ target }) => {
          if (target.value === "") {
            setSearch("");
          }
        }}
      />
      <InfiniteList
        defaultList={UserList}
        key={search}
        loadBy={() => {
          console.log("wha");
          const fetch = new UsersController();
          return fetch.getUsersByPlan(id, search);
        }}
      />
    </>
  );
}
export function UsersListByPayment() {
  const [search, setSearch] = useState("");

  return (
    <>
      <SimpleSearchInput
        onSearch={(value) => {
          console.log(value);
          setSearch(value);
        }}
        onChange={({ target }) => {
          if (target.value === "") {
            setSearch("");
          }
        }}
      />
      <InfiniteList
        defaultList={UserList}
        key={search}
        loadBy={() => {
          const fetch = new UsersController();
          return fetch.getPaids(search);
        }}
      />
    </>
  );
}
export function UsersListByDebt() {
  const [search, setSearch] = useState("");

  return (
    <>
      <SimpleSearchInput
        onSearch={(value) => {
          setSearch(value);
        }}
        onChange={({ target }) => {
          if (target.value === "") {
            setSearch("");
          }
        }}
      />
      <InfiniteList
        key={search}
        defaultList={UserList}
        loadBy={() => {
          console.log(search);
          const fetch = new UsersController();
          return fetch.getDebtors(search);
        }}
      />
    </>
  );
}
