import React from "react";
import { FaIcon } from "../Icons/FontIcon";
import { ActionInput } from "./ActionInput";
export function SimpleSearchInput({ onSearch, onChange }) {
  return (
    <ActionInput
      title="Buscar"
      value=""
      icon={<FaIcon icon="search" />}
      onChange={onChange}
      onClick={onSearch}
    />
  );
}
