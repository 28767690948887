import React, { useState } from "react";
import { GridItem } from "../../components/Grid/Grid";
import { PanelContainer } from "../../components/Panel/PanelContainer";
import { SimpleInput } from "./../../components/CustomInput/SimpleInput";
import { useCState } from "./../../utils/hooks/simpleHooks";
import { generateRandomString, validInputDate } from "../../core/helpers";
import { Button } from "@material-ui/core";

import { CuponController } from "./../../utils/Controller/CuponController";
import { SimpleSelect } from "./../../components/CustomInput/Selector";

import { useHistory } from "react-router-dom";
import {
  clearColor,
  secondaryColor,
} from "../../assets/jss/material-dashboard-react";
import { ConditionalWall } from "../../components/FilterWall/ConditionalWall";
export function CuponForm() {
  const [loading, setLoading] = useState(0);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  let today = validInputDate(new Date().toString());
  const [state, setState] = useCState({
    cupon: generateRandomString(),
    fecha_inicio: today,
    fecha_final: today,
    limite_total: 1,
    fkOGtipo_id: 2,
    descuento: "10",
  });
  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        const fetch = new CuponController();
        setLoading(1);
        fetch.post(state).then((response) => {
          if (response.code === 200) {
            history.push("/cupones");
          }
          console.log(response);
          setErrors(response.message);
          setLoading(0);
        });
      }}>
      <PanelContainer
        title="Registro de cupones"
        style={{
          width: "fit-content",
          maxWidth: "100%",
          minWidth: "350px",
          margin: "0 auto",
        }}>
        <GridItem>
          <SimpleInput
            required
            onChange={({ target }) => {
              setState({ cupon: target.value });
            }}
            title="Cupon"
            value={state.cupon}
          />
        </GridItem>

        <GridItem>
          <SimpleSelect
            title="Tipo"
            defaultValue={{ label: "Porcentaje", value: 2 }}
            onChange={(ev) => {
              setState({ fkOGtipo_id: ev.value });
            }}
            options={[
              { label: "Porcentaje", value: 2 },
              { label: "Fijo", value: "1" },
            ]}
          />
        </GridItem>
        <GridItem>
          <SimpleInput
            required
            onChange={({ target }) => {
              setState({ descuento: target.value });
            }}
            title="Descuento"
            value={state.descuento}
          />
        </GridItem>
        <GridItem>
          <SimpleInput
            required
            onChange={({ target }) => {
              setState({ fecha_inicio: validInputDate(target.value) });
            }}
            title="Arranque"
            type="date"
            value={state.fecha_inicio}
          />
        </GridItem>
        <GridItem>
          <SimpleInput
            required
            errorStatus={errors["fecha_final"]}
            errorMessage={errors["fecha_final"]}
            onChange={({ target }) => {
              setState({ fecha_final: target.value });
            }}
            title="Termino"
            type="date"
            value={state.fecha_final}
          />
        </GridItem>
        <GridItem>
          <SimpleInput
            required
            onChange={({ target }) => {
              setState({ limite_total: target.value });
            }}
            title="Usos"
            type="number"
            value={state.limite_total}
          />
        </GridItem>
        <GridItem>
          <ConditionalWall condition={!loading} or={"Cargando..."}>
            <Button
              color="primary"
              type="submit"
              style={{
                width: "100%",
                background: secondaryColor,
                color: clearColor,
              }}>
              Guardar
            </Button>
          </ConditionalWall>
        </GridItem>
      </PanelContainer>
    </form>
  );
}
