import React, { useState } from "react";
import { between } from "./../core/helpers";
import useEvent from "./../utils/hooks/simpleHooks";
export function ScreenRangeContainer({ min = 0, max = Infinity, children }) {
  const [size, setSize] = useState(window.innerWidth);
  useEvent("resize", () => setSize(window.innerWidth));

  if (between(size, min, max)) {
    return <>{children}</>;
  }
  return <></>;
}
