import { BaseController } from "./BaseController";
import { AuthFetch } from "./../AuthFetch";

export class CategoryController extends BaseController {
  path = "v2.0/categorias";
  pathByProfile = "v2.0/categorias_perfiles";
  deleteCat(id, perfilID) {
    const fetch = new AuthFetch(`${this.path}/${id}/${perfilID}`);
    fetch.delete();
  }
  getCategoriesByProfile(profileID) {
    const fetch = new AuthFetch(
      `${this.pathByProfile}/categorias/${profileID}`
    );
    return fetch.get();
  }
  getAllCategories() {
    const fetch = new AuthFetch(`${this.pathByProfile}`);
    return fetch.get();
  }
  async unlink(idCat, idPerfil) {
    const fetch = new AuthFetch(`${this.pathByProfile}/${idCat}/${idPerfil}`);
    const unLinked = await fetch.delete();
    super.delete(idCat);
    return unLinked;
  }
  updateLink(idCat, fkPerfil, fkPerfilOld) {
    const fetch = new AuthFetch(`${this.pathByProfile}/${idCat}`);
    return fetch.put({ fkPerfil, fkPerfilOld });
  }
  link(id, fkPerfil) {
    const fetch = new AuthFetch(`${this.pathByProfile}`);
    return fetch.post({
      fkCategoria: id,
      fkPerfil,
    });
  }
  async post(content) {
    const fetch = new AuthFetch(`${this.path}`);
    let save = await fetch.post(content);
    if (save.code === 400) {
      return save;
    }
    return this.link(save.data.content[0].id, content.fkPerfil);
  }
}
