import React from "react";
import { LocalSearchLists } from "../../localComponents/Lists";
import { CuponController } from "./../../utils/Controller/CuponController";
import { GridContainer, GridItem } from "./../../components/Grid/Grid";
import { NavLink } from "react-router-dom";
export function CuponView({ cupon, descuento, start, end }) {
  return (
    <GridContainer className="cuponView">
      <GridItem xs={3}>{cupon}</GridItem>
      <GridItem xs={3}>{descuento} %</GridItem>
      <GridItem xs={3}>{new Date(start).toLocaleDateString()}</GridItem>
      <GridItem xs={3}>{new Date(end).toLocaleDateString()}</GridItem>
    </GridContainer>
  );
}
export function CuponList() {
  return (
    <>
      <h3>Cupones</h3>
      <NavLink to="/cupones/form" title="Añadir nuevo">
        Añadir nuevo
      </NavLink>
      <LocalSearchLists
        by={() => {
          const fetch = new CuponController();
          return fetch.get();
        }}
        format={(item) => (
          <GridItem key={"user-" + item.id}>
            <CuponView
              id={item.id}
              cupon={item.cupon}
              descuento={item.descuento}
              start={item.fecha_inicio}
              end={item.fecha_final}
            />
          </GridItem>
        )}
      />
    </>
  );
}
