import React, { useState, useEffect } from "react";
import "./scss/burguerButton.scss";
import { optionalFn } from "../../core/helpers";
import { FaButton } from "./FaButton";
export function BurguerButton(props) {
  const {
    style = {
      display: "flex",
      flexFlow: "column",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    onClick,
    open,
  } = props;
  const [status, setStatus] = useState(open);
  useEffect(() => {
    setStatus(open);
  }, [open]);
  const enabler = status ? "" : "";
  const className = "burguerButton " + enabler;

  return (
    <div
      className={className}
      style={style}
      onClick={() => {
        optionalFn(onClick)(!status);
        setStatus(!status);
      }}
    >
      <FaButton icon={status ? "times" : "bars"} className={className} />
    </div>
  );
}
