import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { AjaxForm } from "../../components/Containers/AjaxForm";
import "./scss/login.scss";
import { LoginManager } from "../../utils/LoginManager";

import logo from "assets/img/svg/logo.svg";
import { SimpleInput } from "../../components/CustomInput/SimpleInput";
import { FaButton } from "../../components/CustomButtons/FaButton";
import { ActionInput } from "../../components/CustomInput/ActionInput";
import { FontIcon } from "../../components/Icons/FontIcon";
import { Version } from "../../components/Prints/Version";

import swal from "sweetalert";
import { Card } from "@material-ui/core";
import {
  primaryColor,
  fontColor,
} from "assets/jss/material-dashboard-react.jsx";

export class Login extends React.Component {
  state = { type: "password", mail: "", loading: false };
  onSubmit(result) {
    const logged = this.props.onLogin;
    if (result.code >= 300) {
      swal("Opps", "Parece que hay un error en tus datos", "error");
      this.setState({ loading: false });
      return false;
    }
    let token = new LoginManager().login(result).getToken();
    if (typeof logged !== "function") {
      throw new Error("on Login Props its needed");
    }
    return logged(token);
  }
  render() {
    const { type, mail } = this.state;
    let typeState = type === "password";
    return (
      <div className="loginBackground">
        <Card className={"login"}>
          <CssBaseline />
          <Avatar className={"avatar"}>
            <img src={logo} alt={"DEVGDL"} style={{ width: "100%" }} />
          </Avatar>
          <Typography
            style={{
              color: fontColor,
              textShadow: "1px 1px 1px black",
              textAlign: "center",
            }}
            component="h1"
            variant="h5"
          >
            Panel administrativo de BuscAmor
          </Typography>
          <AjaxForm
            autoload={false}
            willSubmit={(inputs) => {
              this.setState({ loading: 1 });
              return inputs;
            }}
            path="v2.0/login_admin"
            method="post"
            submitted={this.onSubmit.bind(this)}
          >
            <SimpleInput
              title="Email"
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              style={{ color: "black" }}
              value={mail}
              onBlur={({ target }) => {
                this.setState({ mail: target.value });
              }}
            />
            <ActionInput
              disableEnter
              icon={
                <FontIcon
                  iconName={`fa-eye${typeState ? "" : "-slash"}`}
                  style={{ color: "ligthgray" }}
                />
              }
              onClick={(val) => {
                this.setState({
                  type: typeState ? "text" : "password",
                });
                return val;
              }}
              title="Contraseña "
              name="password"
              type={type}
              id="password"
              autoComplete="current-password"
              style={{ color: "black" }}
              value=""
            />

            <Version />
            {this.state.loading ? (
              "Cargando..."
            ) : (
              <FaButton
                aria-label="Ingresar"
                type="submit"
                size="14px"
                icon="sign-in-alt"
                className={"submit"}
                background={primaryColor}
                style={{
                  color: "white",
                  textShadow: "1px 1px 1px black",
                  width: "100%",
                }}
              >
                Ingresar
              </FaButton>
            )}
          </AjaxForm>
        </Card>
      </div>
    );
  }
}
