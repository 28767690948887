import React, { useEffect, useState } from "react";

import { AnalyticsController } from "./../../utils/Controller/AnalyticsController";
import { validInputDate } from "../../core/helpers";

import { weekNumber } from "weeknumber";

import { GridContainer, GridItem } from "./../../components/Grid/Grid";

import { ConditionalWall } from "./../../components/FilterWall/ConditionalWall";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
function AnaliticsChart({ data }) {
  return (
    <ResponsiveContainer>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="value"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
export function GlobalChart() {
  const [data, setData] = useState();
  let getViews = () => {
    const analitics = new AnalyticsController();
    return analitics.get().then((response) => {
      setData(formatClickData(response.data.content));
    });
  };
  const loadData = () => {
    getViews();
    const interval = setInterval(() => {
      getViews();
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  };
  useEffect(loadData, []);
  return <AnaliticsChart data={data} />;
}
export function RegisterChart() {
  const [data, setData] = useState([]);
  const getViews = () => {
    const fetch = new AnalyticsController();
    fetch.getRegistrations().then((response) => {
      setData(response.data.content);
    });
  };
  const loadData = () => {
    getViews();
    const interval = setInterval(() => {
      getViews();
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  };
  console.log(data);
  useEffect(loadData, []);
  return (
    <GridContainer>
      <FormatRegisterData data={data} />
    </GridContainer>
  );
}
function FormatRegisterData({ data }) {
  const view = [];
  for (let key in data) {
    const item = data[key];

    view.push(
      <GridItem md={4} key={key} style={{ height: "300px", padding: "15px" }}>
        <h3>{key}</h3>
        <AnaliticsChart
          data={item.map(({ date, registros }) => {
            return { name: date, value: registros };
          })}
        />
      </GridItem>,
    );
  }
  return view;
}
export function UserChart({ userID }) {
  const [data, setData] = useState([]);
  let getViews = () => {
    const analitics = new AnalyticsController();
    return analitics.getByUser(userID).then((response) => {
      setData(formatClickData(response.data.content));
    });
  };
  const loadData = () => {
    getViews();
    const interval = setInterval(() => {
      getViews();
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  };
  useEffect(loadData, []);
  return (
    <ConditionalWall condition={data.length > 0}>
      <AnaliticsChart data={data} />
    </ConditionalWall>
  );
}
export function PostChart({ id }) {
  const [data, setData] = useState([]);
  let getViews = () => {
    const analitics = new AnalyticsController();
    return analitics.getByPost(id).then((response) => {
      setData(formatClickData(response.data.content));
    });
  };
  const loadData = () => {
    getViews();
    const interval = setInterval(() => {
      getViews();
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  };

  useEffect(loadData, [id]);
  return <AnaliticsChart data={data} />;
}
function formatClickData(data) {
  data = perDay(data);
  const days = Object.keys(data).length;
  if (days > 31) {
    data = perWeek();
  }
  let result = [];
  for (let key in data) {
    let value = data[key];
    result.push({
      name: key,
      value,
    });
  }
  return result;
}
function perDay(data) {
  let perDay = {};
  for (let key in data) {
    const item = data[key];
    const day = validInputDate(new Date(item.final_periodo));
    if (!perDay[day]) {
      perDay[day] = 0;
    }
    perDay[day] += item.total;
  }
  return perDay;
}
function perWeek(perDays) {
  const perWeek = {};
  for (let key in perDays) {
    const value = perDays[key];
    const date = new Date(key);
    const week = `${date.getFullYear()}-${weekNumber(date)}`;
    if (!perWeek[week]) {
      perWeek[week] = 0;
    }
    perDay[week] += value;
  }
  return perWeek;
}
