import React, { useState } from "react";
import { Popover } from "@material-ui/core";
import { SimpleImageUploader } from "../UploaderInputs/UploaderInput";
export function PopImage({ name, src, children, onChange, style }) {
  const [anchorEl, toggleEl] = useState(0);
  return (
    <div style={{ position: "relative" }}>
      <a
        href="/#"
        onClick={(ev) => {
          ev.preventDefault();
          toggleEl(ev.currentTarget);
        }}
      >
        {name || src}
      </a>
      {children}
      <Popover
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={() => {
          toggleEl(0);
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SimpleImageUploader
          src={src}
          style={{
            width: "700px",
            maxWidth: "80vw",
            ...style,
          }}
          onChange={onChange}
          alt={name}
        />
      </Popover>
    </div>
  );
}
