import React, { useEffect, useState } from "react";
import { PostsController } from "./../../utils/Controller/PostsController";
import { GridContainer, GridItem } from "./../../components/Grid/Grid";
import { useCState } from "./../../utils/hooks/simpleHooks";
import {
  InfoData,
  SimpleToggleInput,
} from "../../components/CustomInput/toggleInput";
import { ToggleInput } from "./../../components/CustomInput/toggleInput";
import { CategorySelectorByProfile } from "../Categories/CategoryForm";
import { SimpleInput } from "../../components/CustomInput/SimpleInput";
import { Textarea } from "./../../components/CustomInput/Textarea";
import "./scss/posts.scss";
import {
  CitySelector,
  StateSelector,
  GenderSelector,
} from "./../../localComponents/Selectors";
import { useParams } from "react-router-dom";
import { PopImage } from "../../components/fancyComponents/PopImage";
import { PostChart } from "./../Charts/AnaliticsChart";
import { WallSize } from "./../../components/FilterWall/SizeWall";
export function SingularPost() {
  const { id } = useParams();
  const [state, setState] = useCState({});
  const [loading, setLoading] = useState(0);
  const load = () => {
    setLoading(1);
    const fetch = new PostsController();
    fetch.show(id).then((response) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setState(response.data.content[0]);
      setLoading(0);
    });
  };
  useEffect(load, [id]);
  if (loading) {
    return <h2>Cargando...</h2>;
  }
  return (
    <>
      <GridContainer className="singularPost">
        <GridItem>
          <GridItem>
            <WallSize minSize={1024}>
              <h3>Visitas</h3>
              <div style={{ height: "30vh" }}>
                <PostChart id={id} />
              </div>
            </WallSize>
          </GridItem>
        </GridItem>
        <GridItem md={9} lg={10}>
          <MainArea state={state} setState={setState} id={id} />
        </GridItem>
        <GridItem lg={2} md={3}>
          <SideBar state={state} id={id} setState={setState} />
        </GridItem>
      </GridContainer>
    </>
  );
}

function MainArea({ state, setState, id }) {
  const save = (values) => {
    const fetch = new PostsController();
    fetch.update(id, values);
    setState(values);
  };
  return (
    <GridContainer>
      <GridItem xs={6}>
        <InfoData title="Creado por" value={state.user_create} />
      </GridItem>
      <GridItem xs={6}>
        <InfoData
          title="Creado el"
          value={new Date(state.fecha_creacion).toLocaleDateString()}
        />
      </GridItem>
      <GridItem s={12}>
        <SimpleToggleInput
          title="Titulo"
          value={state.titulo}
          input={SimpleInput}
          onSave={({ value }) => {
            save({ titulo: value });
          }}
        />
      </GridItem>
      <GridItem s={12} className="descriptionArea">
        <ToggleInput
          title="Descripcion"
          value={state.descripcion}
          input={Textarea}
          onSave={({ value }) => {
            const fetch = new PostsController();
            fetch.update(id, { descripcion: value });
            setState({ descripcion: value });
          }}
          inputProps={{
            onChange: (value) => {
              return { value };
            },
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
function SideBar({ id, state, setState }) {
  const save = (values) => {
    const fetch = new PostsController();
    fetch.update(id, values);
    setState(values);
  };
  return (
    <GridContainer>
      <Pics imgs={state.imagenes} id={state.id} />
      <GridItem s={12}>
        <SimpleToggleInput
          title="Nombre"
          value={state.nombre}
          onSave={({ value }) => {
            save({ nombre: value });
          }}
        />
      </GridItem>
      <GridItem s={12}>
        <ToggleInput
          title="Genero"
          value={state.genero}
          input={GenderSelector}
          onSave={(genero) => {
            const fetch = new PostsController();
            fetch.update(id, { fkGenero: genero.value });
            setState({ genero: genero.label });
          }}
          inputProps={{ onChange: (ev) => ev }}
        />
      </GridItem>
      <GridItem s={12}>
        <SimpleToggleInput
          title="Precio"
          value={state.precio}
          onSave={({ value }) => {
            save({ precio: value });
          }}
        />
      </GridItem>
      <GridItem s={12}>
        <ToggleInput
          title="Categoria"
          value={state.categoria}
          input={CategorySelectorByProfile}
          onSave={(content) => {
            const fetch = new PostsController();
            fetch.update(id, { fkCategoria: content.value });
            setState({ categoria: content.label });
          }}
          inputProps={{
            profileID: state.fkPerfil,
            onChange: (ev) => {
              return ev;
            },
          }}
        />
      </GridItem>
      <GridItem s={12}>
        <SimpleToggleInput
          title="Telefono"
          value={state.telefono}
          onSave={({ value }) => {
            save({ telefono: value });
          }}
        />
      </GridItem>
      <GridItem s={12}>
        <SimpleToggleInput
          title="Celular"
          value={state.whatsapp}
          onSave={({ value }) => {
            save({ whatsapp: value });
          }}
        />
      </GridItem>
      <GridItem s={12}>
        <SimpleToggleInput
          title="Correo"
          value={state.correo}
          onSave={({ value }) => {
            save({ correo: value });
          }}
        />
      </GridItem>
      <GridItem s={12}>
        <ToggleInput
          title="Estado"
          value={state.estado}
          input={StateSelector}
          onSave={(estado) => {
            const fetch = new PostsController();
            fetch.update(id, { fkEstado: estado.value });
            setState({ estado: estado.label });
          }}
          inputProps={{ stateID: state.fkEstado, onChange: (ev) => ev }}
        />
      </GridItem>
      <GridItem s={12}>
        <ToggleInput
          title="Ciudad"
          value={state.ciudad}
          input={CitySelector}
          onSave={(ciudad) => {
            const fetch = new PostsController();
            fetch.update(id, { fkCiudad: ciudad.value });
            setState({ ciudad: ciudad.label });
          }}
          inputProps={{ stateID: state.fkEstado, onChange: (ev) => ev }}
        />
      </GridItem>
      <GridItem s={12}>
        <SimpleToggleInput
          title="Calle"
          value={state.calle}
          input={SimpleInput}
          onSave={({ value }) => {
            save({ calle: value });
          }}
        />
      </GridItem>
      <GridItem s={12}>
        <SimpleToggleInput
          title="Numero"
          value={state.numero}
          onSave={({ value }) => {
            save({ numero: value });
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
function Pics({ imgs = [], id }) {
  imgs = [imgs[0], imgs[1], imgs[2]];
  return (
    <GridItem>
      <div className="InfoData">
        <strong>Imagenes:</strong>
        <div>
          {imgs.map((item, key) => {
            return (
              <PopImage
                name={`Imagen ${key + 1}`}
                src={item}
                key={key}
                onChange={(file) => {
                  const fetch = new PostsController();
                  let content = {};
                  content[`imagen_0${key + 1}`] = file.files[0];
                  fetch.update(id, content);
                }}
                style={{ height: "400px", cursor: "pointer" }}
              />
            );
          })}
        </div>
      </div>
    </GridItem>
  );
}
