import React, { useEffect, useState } from "react";
import { SimpleSelect } from "../../components/CustomInput/Selector";
import { ProfileController } from "./../../utils/Controller/ProfileController";
import { Helpers } from "./../../core/helpers";
/* eslint eqeqeq: 0*/
export function ProfileSelector({ value, ...rest }) {
  const [options, setOptions] = useState([]);
  let val = "";
  const loadProfiles = () => {
    const fetch = new ProfileController();
    fetch.get().then(({ data }) => {
      setOptions(data.content);
    });
  };
  useEffect(loadProfiles, []);
  let filtered = new Helpers().searchByKey(options, "id", value)[0];
  if (filtered) {
    val = { label: filtered.perfil, value: value };
  }
  return (
    <SimpleSelect
      {...rest}
      options={options.map((item) => ({ label: item.perfil, value: item.id,isAdmin:item.isAdmin }))}
      value={val}
    />
  );
}
