import React, { useState } from "react";
import { optionalFn } from "../../core/helpers";
import { GridContainer, GridItem } from "./../Grid/Grid";
import { Button } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import { SimpleInput } from "./SimpleInput";

export function InfoData({ title, value, onClick, className = "" }) {
  return (
    <div
      onClick={(ev) => {
        optionalFn(onClick)();
      }}>
      <GridContainer>
        <GridItem xs={12}>
          <strong>{title}:</strong>
        </GridItem>
        <GridItem className={`value ${className}`} xs={12}>
          {ReactHtmlParser(value)}
        </GridItem>
      </GridContainer>
    </div>
  );
}
export function MiniForm(props) {
  const [values, setValues] = useState({ value: props.value });
  const { title, onSave, onClose, inputProps } = props;
  console.log(values);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <props.input
          {...inputProps}
          onChange={(response) => {
            let result = optionalFn(inputProps.onChange)(response);

            setValues({ value: result.value } || { value: "" });
          }}
          title={title}
          value={values.value}
        />
      </GridItem>
      <GridItem xs={6}>
        <Button
          onClick={() => {
            optionalFn(onSave)(values);
            optionalFn(onClose)(1);
          }}>
          Guardar
        </Button>
      </GridItem>
      <GridItem xs={6}>
        <Button
          onClick={() => {
            optionalFn(onClose)(1);
          }}>
          Cancelar
        </Button>
      </GridItem>
    </GridContainer>
  );
}
export function ToggleInput({ title, value, input, onSave, inputProps }) {
  const [status, setStatus] = useState(1);
  return (
    <div className="InfoData toggleInput">
      {status ? (
        <InfoData
          title={title}
          value={value}
          onClick={() => {
            setStatus(0);
          }}
        />
      ) : (
        <MiniForm
          title={title}
          input={input}
          value={value}
          onSave={onSave}
          inputProps={inputProps}
          onClose={() => {
            setStatus(1);
          }}
        />
      )}
    </div>
  );
}
export function SimpleToggleInput({ title, value, onSave }) {
  return (
    <ToggleInput
      title={title}
      value={value}
      input={SimpleInput}
      onSave={onSave}
      inputProps={{ onChange: ({ target }) => target }}
    />
  );
}
