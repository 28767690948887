import { AuthFetch } from "../AuthFetch";
import { BaseController } from "./BaseController";
import { LoginManager } from "../LoginManager";

export class UsersController extends BaseController {
  path = "v2.0/usuarios";
  authPath = "v2.0/login";
  register = "v2.0/registrar";
  /**
   * Envia un request al endpoint de login
   * @param {user,password} param0
   */
  async login({ email, password }) {
    let fetch = new AuthFetch(this.authPath);
    let result = await fetch.post({ email, password });
    const lm = new LoginManager();
    result.expires_in = 3600 * 24;
    lm.login(result);
    return result;
  }
  /**
   * Actualiza el contenido de user
   * @param {*} id
   * @param {*} userModel
   */
  async update(id, userModel) {
    let fetch = new AuthFetch(`${this.path}/${id}`);
    let result = await fetch.post(userModel, true);
    return result;
  }

  /**
   * Crea un nuevo user
   * @param {*} userModel
   */
  async post(userModel) {
    const fetch = new AuthFetch(this.register);
    const result = await fetch.post(userModel, true);
    return result;
  }
  /**
   * Valida correos
   * @param {*} email
   */
  async checkForMail(email) {
    const fetch = new AuthFetch(this.path + "/correo");
    return fetch.post({ email });
  }
  /**
   * Retorna usuario loggeado
   */
  me() {
    const lm = new LoginManager();
    if (lm.isLogged()) return lm.getToken().data.content[0];
    return {};
  }
  /**
   * Retorna usuarios en base al id del status
   * @param {*} id
   */
  getUsersByStatus(id) {
    const fetch = new AuthFetch(this.path + "/status/" + id);
    return fetch.get();
  }
  getUsersByPlan(id, search = null) {
    search = search !== "" ? `search/${search}` : "";
    const fetch = new AuthFetch(`${this.path}/planes/${id}/${search}`);
    return fetch.get();
  }
  ban(id) {
    const fetch = new AuthFetch(`${this.path}/${id}/banear`);
    return fetch.get();
  }
  unBan(id) {
    const fetch = new AuthFetch(`${this.path}/${id}/unbanned`);
    return fetch.get();
  }
  pay(id) {
    const fetch = new AuthFetch(`${this.path}/${id}/pagar`);
    return fetch.get();
  }
  getPaids(search = null) {
    let path = `${this.path}/pagos/pagados`;
    if (search) {
      path = `v2.0/pagos/pagados/search/${search}`;
    }
    const fetch = new AuthFetch(path);
    return fetch.get();
  }
  getDebtors(search = null) {
    let path = `${this.path}/pagos/adeudos`;
    if (search) {
      path = `v2.0/pagos/adeudos/search/${search}`;
    }
    const fetch = new AuthFetch(path);
    return fetch.get();
  }

  getPayments() {
    const fetch = new AuthFetch(`${this.path}/pagos`);
    return fetch.get();
  }
}
