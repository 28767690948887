import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { FontIcon } from "../Icons/FontIcon";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import "./scss/headers.scss";
import { grayColor } from "../../assets/jss/material-dashboard-react";
import { primaryColor } from "assets/jss/material-dashboard-react.jsx";
import { GridContainer, GridItem } from "./../Grid/Grid";

class HeaderLinks extends React.Component {
  state = {
    open: false,
  };
  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    return (
      <GridContainer className={"customHeader"}>
        <GridItem xs={2}>{this.props.extraButtons}</GridItem>
        <GridItem xs={8}>
          <strong
            style={{
              padding: "12px",
              fontSize: "1.3rem",
              textTransform: "full-width capitalize",
              display: "block",
              color: primaryColor,
            }}
          >
            <FontIcon iconName={`fa-${this.props.icon}`} /> {this.props.name}
          </strong>
        </GridItem>
        <GridItem
          xs={2}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Tooltip title="Salir">
            <IconButton
              onClick={() => {
                localStorage.removeItem(btoa("token"));
              }}
            >
              <FontIcon
                iconName="fa-sign-out-alt"
                style={{ color: grayColor }}
              />
            </IconButton>
          </Tooltip>
        </GridItem>
      </GridContainer>
    );
  }
}
export default withStyles(headerLinksStyle)(HeaderLinks);
