import React, { useState, useEffect } from "react";
import { PostsController } from "./../../utils/Controller/PostsController";
import { GridContainer, GridItem } from "./../../components/Grid/Grid";
import "./scss/posts.scss";
import { InfoData } from "./../../components/CustomInput/toggleInput";
import { optionalFn } from "../../core/helpers";
import { ConfirmButton } from "./../../components/CustomButtons/ConfirmButtom";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Carrousel } from "./../../components/Containers/Carrousel";
import { Image } from "./../../components/Images/Image";
export function ValidatorContainer() {
  const [posts, setPosts] = useState([]);
  const [pos, setPos] = useState(0);
  const loadPosts = () => {
    const fetch = new PostsController();
    fetch.getByStatus({ id: 2, itemsPerPage: 100 }).then((response) => {
      if (response.code < 300) {
        setPosts(response.content.data);
      }
    });
  };
  useEffect(loadPosts, []);
  const history = new useHistory();
  if (posts.length === 0) {
    return <h3>Todos los anuncios fueron revisados</h3>;
  }
  const {
    id,
    descripcion,
    titulo,
    user_create,
    imagenes,
    nombre,
    categoria,
  } = posts[pos];
  return (
    <ValidatorPost
      id={id}
      images={imagenes}
      title={titulo}
      category={categoria}
      author={user_create}
      desc={descripcion}
      name={nombre}
      current={pos}
      total={posts.length}
      onComplete={() => {
        if (pos + 1 === posts.length) {
          history.push("/");
        } else {
          setPos(pos + 1);
        }
      }}
    />
  );
}
export function ValidatorPost({
  id,
  desc,
  author,
  total,
  current,
  images,
  title,
  name,
  category,
  onComplete,
}) {
  return (
    <GridContainer className="validatorPost">
      <GridItem md={8} className="imagesContainer">
        <Carrousel>
          {images.map((item, key) => (
            <Image type="Gallery" key={key} src={"https://" + item} />
          ))}
        </Carrousel>
      </GridItem>
      <GridItem md={4} className="sideContainer">
        <div className="counter">
          {current + 1}/{total} por validar...
        </div>
        <h4>{title}</h4>
        <InfoData title="Autor" value={author} />
        <InfoData title="Categoria" value={category} />
        <InfoData title="Nombre" value={name} />
        <InfoData title="Descripcion" className="desc" value={desc} />
        <GridContainer className="buttons">
          <GridItem xs={6}>
            <ConfirmButton
              title="Validar"
              helpText="Esta accion no puede deshacerse"
              comp={Button}
              fullWidth
              onClick={() => {
                optionalFn(onComplete)();
                const fetch = new PostsController();
                fetch.activate(id);
              }}
            >
              Validar
            </ConfirmButton>
          </GridItem>
          <GridItem xs={6}>
            <ConfirmButton
              title="Eliminar"
              helpText="Despues de eliminar este anuncio, no podras recuperarle"
              comp={Button}
              fullWidth
              onClick={() => {
                optionalFn(onComplete)();
                const fetch = new PostsController();
                fetch.delete(id);
              }}
              color="secondary"
            >
              Eliminar
            </ConfirmButton>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}
