import React, { Component, useState, useEffect } from "react";
import { Helpers, optionalFn, filesToB64 } from "../../core/helpers";
import { FontIcon } from "../Icons/FontIcon";
import { Image } from "../Images/Image";
export class UploaderInput extends Component {
  async getFile(ev) {
    const helpers = new Helpers();
    const file = ev.target.files[0];
    const b64 = await helpers.fileTo64(file);
    const fileData = {
      title: file.name,
      size: file.size,
      type: file.type,
      b64: b64.target.result,
    };
    this.props.onFileSelection(fileData);
  }
  render() {
    const { name, onChange, title, ...rest } = this.props;
    return (
      <span>
        <label
          htmlFor={name}
          color="primary"
          style={{
            cursor: "pointer",
          }}
        >
          <FontIcon iconName="fa-upload" title={title}>
            {title}
          </FontIcon>
        </label>
        <input
          type="file"
          name={name}
          id={name}
          onChange={onChange || this.getFile.bind(this)}
          className="hidden"
          {...rest}
        />
      </span>
    );
  }
}
export function SimpleImageUploader({
  src,
  maxSize = 1024,
  type,
  onChange,
  style,
}) {
  const [image, setImage] = useState("");
  let number = new Date().getTime() / 1000;
  useEffect(() => {
    setImage(`https://${src}`);
  }, [src]);
  return (
    <>
      <label
        htmlFor={`image-uploader-${number}`}
        className="imageSelector"
        style={{ display: "block", margin: " 0 auto", width: "fit-content" }}
      >
        <Image src={image} type={type} style={style} />
      </label>
      <input
        type="file"
        id={`image-uploader-${number}`}
        className="hidden"
        onChange={({ target }) => {
          filesToB64(target.files).then((files) => {
            setImage(files[0].target.result);
            optionalFn(onChange)(target, files[0].target.result);
          });
        }}
      />
    </>
  );
}
