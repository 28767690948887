import { BaseController } from "./BaseController";
import { AuthFetch } from "./../AuthFetch";

export class StatusController extends BaseController {
  path = "v2.0/record_status";
  statusPath = "v2.0/usuarios/status";
  getUsersQuantity() {
    const fetch = new AuthFetch(this.statusPath);
    return fetch.get();
  }
}
