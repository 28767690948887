import React from "react";
//import AsyncSelect from "react-select/async";
import Select from "react-select";

export function SimpleSelect({
  title,
  placeholder = "selecciona uno",
  className,
  error,
  errMsg = "Campo invalido",
  ...rest
}) {
  return (
    <div className="simpleSelect">
      <label>{title}</label>
      <Select
        placeholder={placeholder}
        className={`simpleSelect ${className}`}
        classNamePrefix="simpleSelect"
        {...rest}
      />
      {error ? <p className="errorMessage">{errMsg}</p> : ""}
    </div>
  );
}
