import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useCState } from "./../../utils/hooks/simpleHooks";
import { SectionController } from "./../../utils/Controller/SectionsController";
import { useEffect } from "react";
import { PanelContainer } from "./../../components/Panel/PanelContainer";
import { GridItem } from "../../components/Grid/Grid";
import { SimpleInput } from "../../components/CustomInput/SimpleInput";
import { Button } from "@material-ui/core";
import { successColor } from "assets/jss/material-dashboard-react.jsx";
import { ConditionalWall } from "../../components/FilterWall/ConditionalWall";
export function SectionForm() {
  const { id } = useParams();
  const history = useHistory();
  const [state, setState] = useCState();
  const [loading, setLoad] = useState(0);
  const load = () => {
    const fetch = new SectionController();
    fetch.show(id).then((response) => setState(response.data.content[0]));
  };
  useEffect(load, [id]);
  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        setLoad(1);
        const fetch = new SectionController();
        fetch.put(id, state).then((response) => {
          if (response.code < 300) {
            history.push("/");
          }
        });
      }}
    >
      <PanelContainer
        title={`Editor de Planes (${state.plan || ""})`}
        subtitle={state.plan}
      >
        <GridItem>
          <SimpleInput
            title="Plan"
            type="text"
            value={state.plan}
            onChange={({ target }) => {
              setState({ plan: target.value });
            }}
          />
        </GridItem>
        <GridItem>
          <SimpleInput
            title="Periodo"
            type="number"
            value={state.periodo}
            onChange={({ target }) => {
              setState({ periodo: target.value });
            }}
          />
        </GridItem>
        <GridItem>
          <SimpleInput
            title="Precio"
            type="number"
            value={state.precio}
            onChange={({ target }) => {
              setState({ precio: target.value });
            }}
          />
        </GridItem>
        <GridItem>
          <SimpleInput
            title="Subidas diarias"
            value={state.subidas_diarias}
            type="number"
            onChange={({ target }) => {
              setState({ subidas_diarias: target.value });
            }}
          />
        </GridItem>
        <GridItem>
          <SimpleInput
            title="Descripcion"
            type="text"
            value={state.descripcion}
            onChange={({ target }) => {
              setState({ descripcion: target.value });
            }}
          />
        </GridItem>
        <GridItem>
          <ConditionalWall condition={!loading} or={"Cargando..."}>
            <Button style={{ color: successColor }} fullWidth type="submit">
              Guardar
            </Button>
          </ConditionalWall>
        </GridItem>
      </PanelContainer>
    </form>
  );
}
