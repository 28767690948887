import React, { useEffect, useState } from "react";
import { CategoryController } from "./../../utils/Controller/CategoryController";
import { IconicCard } from "./../../localComponents/Cards";
import { GridContainer, GridItem } from "../../components/Grid/Grid";
import {
  infoColor,
  primaryColor,
} from "assets/jss/material-dashboard-react.jsx";
import { SimpleMenu } from "./../../components/menu/menus";
import { SectionController } from "./../../utils/Controller/SectionsController";
import { abstractNumber } from "./../../core/helpers";
import { StatusController } from "./../../utils/Controller/StatusController";
import { dangerColor } from "../../assets/jss/material-dashboard-react";
import { NavLink } from "react-router-dom";
import { UsersController } from "../../utils/Controller/UsersController";
export function CategoriesGrid() {
  const [categories, setCategories] = useState([]);
  const [total, setTotal] = useState(0);
  const load = () => {
    const fetch = new CategoryController();
    fetch.get().then((response) => {
      setCategories(response.data.content);
      let posts = response.data.content.reduce((adder, item) => {
        return isNaN(adder)
          ? adder.anuncios_totales + item.anuncios_totales
          : adder + item.anuncios_totales;
      });
      setTotal(posts);
    });
  };
  useEffect(load, []);
  return (
    <GridContainer>
      <GridItem xs={12} s={6} md={3}>
        <SimpleMenu items={[{ title: "Ver anuncios", path: `/posts/` }]}>
          <IconicCard
            color={primaryColor}
            icon="ad"
            subtitle={`Anuncios en total`}
            mainText={total}
          />
        </SimpleMenu>
      </GridItem>
      {categories.map((item, key) => (
        <CategoryCard
          key={"cat-" + item.id}
          id={item.id}
          index={key}
          count={item.anuncios_totales}
          name={item.categoria}
        />
      ))}
    </GridContainer>
  );
}
export function CategoryCard({ name, count, index, id }) {
  if (count === 0) {
    return <></>;
  }
  return (
    <GridItem xs={12} s={6} md={3}>
      <SimpleMenu
        items={[
          { title: "Ver anuncios", path: `/posts/category/${id}` },
          { title: "Editar Categoria", path: `/category/${id}` },
        ]}>
        <IconicCard
          color={infoColor}
          index={index + 1}
          subtitle={`Anuncios en ${name}`}
          mainText={count}
        />
      </SimpleMenu>
    </GridItem>
  );
}
export function PlanGrid() {
  const [sections, setSections] = useState([]);
  const load = () => {
    const fetch = new SectionController();
    fetch.get().then((response) => {
      setSections(response.data.content);
    });
  };
  useEffect(load, []);
  return (
    <GridContainer>
      {sections.map((item, key) => (
        <PlanCard
          key={key}
          totalUsers={item.usuarios_totales}
          price={item.precio}
          id={item.id}
          index={key}
          name={item.plan}
        />
      ))}
    </GridContainer>
  );
}
export function PlanCard({ totalUsers, price, index, id, name }) {
  let earnings = price * totalUsers;
  return (
    <GridItem xs={12} s={6} md={3}>
      <SimpleMenu
        items={[
          { title: "Ver Usuarios", path: `users/sections/${id}` },
          { title: "Ver anuncios", path: `/posts/section/${id}` },
          { title: "Editar Paquete", path: `/sections/${id}` },
        ]}>
        <IconicCard
          color={infoColor}
          index={index + 1}
          subtitle={`Ganancias estimadas al mes del paquete: ${name}`}
          mainText={
            <span style={{ color: "green" }}>${abstractNumber(earnings)}</span>
          }>
          <i>Hay {totalUsers} usuarios activos</i>
        </IconicCard>
      </SimpleMenu>
    </GridItem>
  );
}
export function UserStatusGrid() {
  const [items, setItems] = useState([]);
  const load = () => {
    const fetch = new StatusController();
    fetch.getUsersQuantity().then((response) => {
      setItems(response.data.content);
    });
  };
  useEffect(load, []);
  return (
    <GridContainer>
      {items.map((item, key) => {
        if (item.total_usuarios === 0)
          return <div key={key} style={{ display: "none" }} />;
        return (
          <GridItem xs={12} s={6} md={3} key={`status-${key}`}>
            <NavLink to={`/users/status/${item.id}`}>
              <IconicCard
                color={
                  item.recordStatus === "BANEADO" ? dangerColor : infoColor
                }
                icon={"users"}
                subtitle={`Usuarios ${item.recordStatus}`}
                mainText={item.total_usuarios}
              />
            </NavLink>
          </GridItem>
        );
      })}
    </GridContainer>
  );
}
export function StatusCard({ status, index, id, users }) {
  return (
    <GridItem xs={12} s={6} md={3}>
      <SimpleMenu
        items={[
          { title: "Ver anuncios", path: `/posts/category/${id}` },
          { title: "Editar Categoria", path: `/category/${id}` },
        ]}>
        <IconicCard
          color={infoColor}
          index={index + 1}
          subtitle={`Usuarios en status ${status}`}
          mainText={users}
        />
      </SimpleMenu>
    </GridItem>
  );
}
export function PaymentStatus() {
  const [totales, setTotales] = useState({
    total_pagados: 0,
    total_adeudos: 0,
  });
  const loadData = () => {
    const fetch = new UsersController();
    fetch.getPayments().then((response) => {
      setTotales(response.data.content);
    });
  };
  useEffect(loadData, []);
  return (
    <GridContainer>
      <GridItem xs={12} s={6} md={3}>
        <NavLink to={`/users/payments`}>
          <IconicCard
            color={infoColor}
            icon={"dollar-sign"}
            mainText={totales.total_pagados}
            subtitle={"Al corriente"}
          />
        </NavLink>
      </GridItem>
      <GridItem xs={12} s={6} md={3}>
        <NavLink to={`/users/debtors`}>
          <IconicCard
            color={dangerColor}
            icon={"dollar-sign"}
            mainText={totales.total_adeudos}
            subtitle={"Pendientes de pago"}
          />
        </NavLink>
      </GridItem>
    </GridContainer>
  );
}
