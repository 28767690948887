import React, { useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { GridContainer, GridItem } from "./../../components/Grid/Grid";
import { Image } from "../../components/Images/Image";
import { PostsController } from "./../../utils/Controller/PostsController";
import { List, InfiniteList } from "../../localComponents/Lists";
import "./scss/posts.scss";
import { SimpleSearchInput } from "../../components/CustomInput/SearchInput";
import { FaButton } from "./../../components/CustomButtons/FaButton";
import {
  dangerColor,
  infoColor,
} from "../../assets/jss/material-dashboard-react";
import { successColor } from "assets/jss/material-dashboard-react.jsx";
import { ActionsResponsiveMenu } from "./../../localComponents/SimpleMenu";
import { ConfirmButton } from "./../../components/CustomButtons/ConfirmButtom";
export function PostsPreview({
  img,
  title,
  creator,
  creatorID,
  status,
  id,
  createdAt,
  fkRecordStatus,
}) {
  const history = useHistory();
  const [deleted, setDelete] = useState(0);
  if (deleted) return <></>;
  return (
    <GridContainer className="postPreview">
      <GridItem xs={12} md={1} className="imageContainer">
        <Image src={img} />
      </GridItem>
      <GridItem xs={12} md={3} className="title">
        {title}
      </GridItem>
      <GridItem xs={6} md={2} className="subtext">
        {new Date(createdAt).toLocaleDateString()}
      </GridItem>
      <GridItem xs={6} md={2} className="subtext">
        {status}
      </GridItem>
      <GridItem xs={12} md={2} className="subtext name">
        <NavLink to={`/users/edit/${creatorID}`}>{creator}</NavLink>
      </GridItem>
      <GridItem xs={12} md={2} className="actionMenu">
        <ActionsResponsiveMenu>
          <FaButton
            title="Editar"
            icon="edit"
            style={{ color: infoColor }}
            onClick={() => {
              history.push(`/posts/edit/${id}`);
            }}
          />
          {fkRecordStatus !== 5 ? (
            <ConfirmButton
              comp={FaButton}
              icon="check"
              title="Validar"
              style={{ color: successColor }}
              onClick={() => {
                const fetch = new PostsController();
                fetch.activate(id);
                setDelete(1);
              }}
            />
          ) : (
            ""
          )}

          <ConfirmButton
            comp={FaButton}
            icon="trash"
            title="Eliminar"
            style={{ color: dangerColor }}
            onClick={() => {
              const fetch = new PostsController();
              fetch.delete(id);
            }}
          />
        </ActionsResponsiveMenu>
      </GridItem>
    </GridContainer>
  );
}
export function PostsList({
  status = 1,
  page = 1,
  load = false,
  onLoad,
  loadBy,
  ...rest
}) {
  return (
    <List
      page={page}
      onLoad={onLoad}
      load={load}
      by={{
        getter: () => {
          return loadBy();
        },
      }}
      format={(item,key) => (
        <PostsPreview
          key={key}
          title={item.titulo}
          fkRecordStatus={item.fkRecordStatus}
          creator={item.user_create}
          creatorID={item.fkUser}
          status={item.status}
          createdAt={item.fecha_creacion}
          id={item.id}
          img={`https://${item.imagenes[0]}`}
        />
      )}
    />
  );
}
export function LazyPostsListByStatus({ status }) {
  return (
    <InfiniteList
      status={status}
      defaultList={PostsList}
      loadBy={() => {
        const fetch = new PostsController();
        return fetch.getByStatus({ id: status });
      }}
    />
  );
}
export function LazyPostsListByCategory({ category }) {
  const [search, setSearch] = useState("");
  return (
    <>
      <SimpleSearchInput
        onSearch={(value) => {
          setSearch(value);
        }}
        onChange={({ target }) => {
          if (target.value === "") {
            setSearch("");
          }
        }}
      />
      <InfiniteList
        key={search}
        status={category}
        defaultList={PostsList}
        loadBy={() => {
          const fetch = new PostsController();
          return fetch.getByCategory({ id: category, search });
        }}
      />
    </>
  );
}
export function PostsView() {
  return (
    <>
      <GridContainer>
        <GridItem xs={12} className="postSelector">
          <h2>Revision</h2>
          <LazyPostsListByStatus status={2} />
          <h2>Publicado</h2>
          <LazyPostsListByStatus status={5} />
        </GridItem>
      </GridContainer>
    </>
  );
}
export function CategoryPostView() {
  const { id } = useParams();
  return <LazyPostsListByCategory category={id} />;
}
export function LazyPostsByUser({ id }) {
  return (
    <InfiniteList
      defaultList={PostsList}
      loadBy={() => {
        const fetch = new PostsController();
        return fetch.getByUser({ id });
      }}
    />
  );
}
export function LazyPostsByPlan() {
  const { id } = useParams();
  return (
    <InfiniteList
      defaultList={PostsList}
      loadBy={() => {
        const fetch = new PostsController();
        return fetch.getByPlan({ id });
      }}
    />
  );
}
