import React from "react";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import "./scss/card.scss";
import { FaIcon } from "../components/Icons/FontIcon";

export function IconicCard({
  icon,
  index,
  color,
  subtitle,
  mainText,
  onClick,
  children,
}) {
  return (
    <Card className="iconicCard" onClick={onClick}>
      <CardContent>
        <div className="iconContainer" style={{ background: color }}>
          {icon ? (
            <FaIcon icon={icon || "clock"} style={{ color: "white" }} />
          ) : (
            index
          )}
        </div>
        <div>
          <h3>{mainText}</h3>
        </div>
        <div className="subtitle">
          <p>{subtitle}</p>
        </div>
        {children}
      </CardContent>
    </Card>
  );
}
