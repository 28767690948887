import React, { useState, useEffect } from "react";
import { GridItem } from "./../../components/Grid/Grid";
import { SimpleInput } from "./../../components/CustomInput/SimpleInput";
import { CategoryController } from "./../../utils/Controller/CategoryController";
import { useCState, useQuery } from "./../../utils/hooks/simpleHooks";
import { PanelContainer } from "../../components/Panel/PanelContainer";
import { Button } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Helpers } from "./../../core/helpers";
import { SimpleSelect } from "./../../components/CustomInput/Selector";
import { ProfileSelector } from "./../Profiles/ProfileSelector";
import {
  secondaryColor,
  clearColor,
} from "assets/jss/material-dashboard-react.jsx";
import { ConditionalWall } from "../../components/FilterWall/ConditionalWall";

export function CategoryForm() {
  const { id } = useParams();
  const { profile } = useQuery();
  const history = useHistory();
  const [state, setState] = useCState({ fkPerfil: profile ,categoria:"",descripcion:""});
  const [loading, setLoading] = useState(0);
  const [errors, setErrors] = useState([]);
  const load = () => {
    if (!id) {
      return;
    }
    setLoading(1);
    const fetch = new CategoryController();
    fetch.show(id).then((response) => {
      setLoading(0);
      setState(response.data.content[0]);
    });
  };
  const request = () => {
    const fetch = new CategoryController();
    return id ? fetch.put(id, state) : fetch.post(state);
  };
  useEffect(load, [id]);

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        setLoading(1);
        request(state).then((response) => {
          if (response.code === 200) history.push("/categories");
          setLoading(0);
          setErrors(response.message);
        });
      }}
    >
      <PanelContainer
        title="Creador de categorias"
        subtitle="Rellena los datos para crear una nueva categoria"
        style={{
          width: "fit-content",
          maxWidth: "100%",
          minWidth: "350px",
          margin: "0 auto",
        }}
      >
        {!state.id ? (
          <GridItem xs={12}>
            <ProfileSelector
              title="Perfil Selector"
              value={state.fkPerfil}
              onChange={(values) => {
                setState({ fkPerfil: values.value });
              }}
            />
          </GridItem>
        ) : (
          ""
        )}
        <GridItem xs={12}>
          <SimpleInput
            title="Categoria"
            required
            errorStatus={errors["categoria"]}
            errorMessage={errors["categoria"] ? errors["categoria"][0] : ""}
            value={state.categoria}
            onChange={({ target }) => {
              setState({ categoria: target.value });
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <SimpleInput
            required
            title="Descripción"
            value={state.descripcion}
            onChange={({ target }) => {
              setState({ descripcion: target.value });
            }}
          />
        </GridItem>

        <GridItem xs={12}>
          <ConditionalWall condition={loading}>Cargando...</ConditionalWall>
          <ConditionalWall condition={!loading}>
            <Button
              color="primary"
              type="submit"
              style={{
                width: "100%",
                background: secondaryColor,
                color: clearColor,
              }}
            >
              Guardar
            </Button>
          </ConditionalWall>
        </GridItem>
      </PanelContainer>
    </form>
  );
}
export function CategorySelectorByProfile({ profileID, value, ...rest }) {
  const [options, setOptions] = useState([]);
  let val = "";
  const load = () => {
    const fetch = new CategoryController();
    fetch.getCategoriesByProfile(profileID).then((response) => {
      setOptions(response.data.content);
    });
  };
  useEffect(load, []);
  let filtered = new Helpers().searchByKey(options, "fkCategoria", value)[0];
  if (filtered) {
    val = { label: filtered.categoria, value: value };
  }
  return (
    <SimpleSelect
      {...rest}
      options={options.map((item) => ({
        label: item.categoria,
        value: item.fkCategoria,
      }))}
      value={val}
    />
  );
}
