import React, { useEffect } from "react";
import { CuponController } from "./../../utils/Controller/CuponController";
export function Sandbox() {
  const load = () => {
    const fetch = new CuponController();
    fetch.get();
  };
  useEffect(load, []);
  return <div style={{ height: "30vh" }}></div>;
}
