import React, { useState, useEffect } from "react";
import { optionalFn } from "../core/helpers";
import { PlaceController } from "../utils/Controller/PlacesController";
import { GridItem } from "../components/Grid/Grid";
import { CategoryController } from "../utils/Controller/CategoryController";
import { GenderController } from "./../utils/Controller/GenderController";
import { GridContainer } from "./../components/Grid/Grid";
import { ProfileController } from "./../utils/Controller/ProfileController";
import { SimpleSelect } from "./../components/CustomInput/Selector";

/**
 *
 * retorna dos selects
 * @param {onChange,error} props
 * @return component
 *
 */
export function LocationSelector({ onChange, error, id }) {
  const [stateId, setStateID] = useState(null);

  return (
    <GridContainer id={id} className={error ? "error" : ""}>
      <StateSelector
        onChange={({ value }) => {
          setStateID(value);
        }}
      />
      {stateId ? (
        <CitySelector
          stateID={stateId}
          onChange={({ value }) => {
            optionalFn(onChange)({ city: value, state: stateId });
          }}
        />
      ) : (
        ""
      )}
      {error ? (
        <GridItem xs={12}>
          <p className="errorMessage">Selecciona una opción</p>
        </GridItem>
      ) : (
        ""
      )}
    </GridContainer>
  );
}
/**
 * Retorna un selector de estado
 * @param {onChange} param0
 * @return component
 */
export function StateSelector({ onChange }) {
  const [states, setStates] = useState([]);

  const loadState = async () => {
    let place = new PlaceController();
    let locations = (await place.getStates()).content;
    setStates(locations);
  };
  useEffect(() => {
    loadState();
  }, []);

  return (
    <GridItem xs={12}>
      <SimpleSelect
        required
        title="Estado"
        options={formatOptions(states)}
        placeholder="Elige un estado"
        onChange={onChange}
      />
    </GridItem>
  );
}
/**
 * Retorna un selector de ciudad de unestado predefinido
 * @param {stateID,onChange} param0
 * @return component
 */
export function CitySelector({ stateID, onChange }) {
  const [cities, setCities] = useState([]);
  const [value, setValue] = useState(null);
  const loadCities = () => {
    const locations = new PlaceController();
    locations.getCities(stateID).then(({ content }) => {
      setCities(content);
    });
  };
  useEffect(() => {
    loadCities();
    setValue(null);
    //eslint-disable-next-line
  }, [stateID]);

  return (
    <GridItem xs={12}>
      <SimpleSelect
        value={value}
        required
        title="Ciudad"
        options={cities.map((item) => {
          return { value: item.id, label: item.ciudad };
        })}
        onChange={(ev) => {
          setValue(ev);
          optionalFn(onChange)(ev);
        }}
        placeholder="Elige una ciudad"
      />
    </GridItem>
  );
}
function formatOptions(options = []) {
  return options.map((item, key) => {
    return { value: item.id, label: item.estado };
  });
}

/**
 * Retorna un select que obtiene todas las categorias
 * @param {value,onChange} param0
 * @return component
 */
export function SelectCategory({ value, onChange, ...rest }) {
  const [category, setCategory] = useState([]);
  const loadCategories = async () => {
    let cat = new CategoryController();
    let { data } = await cat.get();
    setCategory(data.content);
  };
  useEffect(() => {
    loadCategories();
  }, []);
  return (
    <GridItem xs={12}>
      <SimpleSelect
        defaultValue={value ? { value } : null}
        {...rest}
        onChange={onChange}
        required
        title="Categoría"
        placeholder="Elige una categoría"
        options={category.map((item) => ({
          value: item.id,
          label: item.categoria,
        }))}
      />
    </GridItem>
  );
}
/**
 * Creo que el nombre es bastante descriptivo.
 * @param {value,onChange,error,...rest} param0
 */
export function GenderSelector({ value, onChange, error, ...rest }) {
  const [genders, setGenders] = useState([]);
  const loadGenders = () => {
    const fetch = new GenderController();
    fetch.get().then((response) => {
      setGenders(response.content);
    });
  };
  useEffect(() => {
    loadGenders();
  }, []);
  return (
    <GridItem xs={12}>
      <SimpleSelect
        className={error ? "error" : ""}
        defaultValue={value ? { value } : null}
        {...rest}
        onChange={onChange}
        required
        title="Genero"
        placeholder="Elige una categoría"
        options={genders.map((item) => ({
          label: item.genero,
          value: item.id,
        }))}
      />
    </GridItem>
  );
}
export function ProfileSelector({ value, onChange, error, ...rest }) {
  const [profiles, setProfiles] = useState([]);
  const loadProfiles = () => {
    const fetch = new ProfileController();
    fetch.get().then((response) => {
      setProfiles(response.data.content);
    });
  };
  useEffect(() => {
    loadProfiles();
  }, []);
  return (
    <GridItem xs={12}>
      <SimpleSelect
        className={error ? "error" : ""}
        defaultValue={value ? { value } : null}
        {...rest}
        onChange={onChange}
        required
        title="Perfil"
        placeholder="Elige una categoría"
        options={profiles.map((item) => ({
          label: item.perfil,
          value: item.id,
        }))}
      />
    </GridItem>
  );
}
