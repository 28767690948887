import React, { useState, useEffect } from "react";
import {
  CategoriesGrid,
  PlanGrid,
  UserStatusGrid,
  PaymentStatus,
} from "./DashboardComponents";
import { Button, Badge } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { PostsController } from "./../../utils/Controller/PostsController";
import { numberPadStart } from "./../../core/helpers";
import { GlobalChart, RegisterChart } from "./../Charts/AnaliticsChart";
import { GridItem } from "../../components/Grid/Grid";
import { GridContainer } from "./../../components/Grid/Grid";
import { WallSize } from "../../components/FilterWall/SizeWall";

export function Home() {
  return (
    <div className="dashboard">
      <WallSize minSize={1024}>
        <section>
          <h3>Vistas</h3>
          <GridContainer>
            <GridItem md={12}>
              <div style={{ height: "30vh" }}>
                <GlobalChart />
              </div>
            </GridItem>
            <GridItem md={12}>
              <h3>Registros</h3>
              <RegisterChart />
            </GridItem>
          </GridContainer>
        </section>
      </WallSize>
      <section>
        <h3>Usuarios</h3>
        <UserStatusGrid />
        <PaymentStatus />
      </section>
      <section>
        <h4>Paquetes</h4>
        <PlanGrid />
      </section>
      <section>
        <h3>Anuncios</h3>
        <ValidPostButton />
        <CategoriesGrid />
      </section>
    </div>
  );
}
function ValidPostButton() {
  const [status, setStatus] = useState(0);
  const load = () => {
    const fetch = new PostsController();
    fetch.getQtyStatus().then((response) => {
      setStatus(
        response.data.content.filter((item) => item.id === 2)[0].total_anuncios,
      );
    });
  };
  useEffect(load, []);
  return (
    <NavLink to="/posts/validate">
      <Badge badgeContent={`${numberPadStart(1, status)}`} color="primary">
        <Button>Validar anuncios</Button>
      </Badge>
    </NavLink>
  );
}
