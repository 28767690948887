import React from "react";
import { Translator } from "../../utils/Translator";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import "./panel.scss";
import { FaButton } from "../CustomButtons/FaButton";
import { ConditionalWall } from "../FilterWall/ConditionalWall";
import { GridContainer } from "./../Grid/Grid";

export function PanelContainer({
  title,
  footer,
  onAdd,
  style,
  children,
  className,
}) {
  return (
    <Card style={style} className={`panelContainer ${className}`}>
      <h5 style={{ textAlign: "center", fontWeight: "bolder" }}>
        {new Translator(title).get()}
        <ConditionalWall conditional={onAdd}>
          <FaButton icon="plus" onClick={onAdd} size="1rem" />
        </ConditionalWall>
      </h5>
      <CardBody className={"cardBody"}>
        <GridContainer justify="center" aligncontent="center">
          {children}
        </GridContainer>
      </CardBody>
      <CardFooter>{footer}</CardFooter>
    </Card>
  );
}
