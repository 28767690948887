import React, { useState, useEffect, useReducer } from "react";
import { CategoryController } from "./../../utils/Controller/CategoryController";
import { DataTable } from "../../components/Table/DataTable";
import { FaButton } from "../../components/CustomButtons/FaButton";
import { useHistory } from "react-router-dom";

import {
  dangerColor,
  secondaryColor,
  primaryColor,
  infoColor,
} from "../../assets/jss/material-dashboard-react";
import "./scss/cat.scss";
import { GridItem, GridContainer } from "../../components/Grid/Grid";
import { PanelContainer } from "../../components/Panel/PanelContainer";
import { ConfirmButton } from "./../../components/CustomButtons/ConfirmButtom";
import { SimpleMenu } from "../../components/menu/menus";
import { SelectCategory } from "../../localComponents/Selectors";
import { ConditionalWall } from "../../components/FilterWall/ConditionalWall";
import { successColor } from "assets/jss/material-dashboard-react.jsx";
import { optionalFn } from "../../core/helpers";
export function CategoriesContainer() {
  const history = useHistory();
  const [content, setContent] = useState([]);
  const load = () => {
    const fetch = new CategoryController();
    fetch.getAllCategories().then((response) => {
      let content = response.data.content.map((item) => {
        let values = Object.values(item);
        values.push(
          <>
            <FaButton
              icon="edit"
              style={{ color: primaryColor, fontSize: "12px" }}
              onClick={() => {
                //setID(item.id);
                //toggleModal(true);
              }}
            />
            <FaButton
              icon="eye"
              style={{ color: secondaryColor, fontSize: "12px" }}
              onClick={() => {
                //console.log(item.id);
              }}
            />
            <FaButton
              icon="trash"
              style={{ color: dangerColor, fontSize: "12px" }}
              onClick={() => {
                fetch.deleteCat(item.id);
              }}
            />
          </>
        );
        return values;
      });
      setContent(content);
    });
  };
  useEffect(load, []);
  return (
    <DataTable
      title="Lista de categorias"
      onAdd={() => {
        history.push("cat/register");
      }}
      subtitle="Tabla para editar y modificar elementos."
      titles={["id", "categoria", "descripcion", "anuncios", "Acciones"]}
      content={content}
    />
  );
}
export function CategoriesGridByProfile() {
  const [profiles, setProfiles] = useState();
  const load = () => {
    const fetch = new CategoryController();
    fetch.getAllCategories().then((response) => {
      setProfiles(response.data.content);
    });
  };
  useEffect(load, []);
  return (
    <GridContainer>
      <ProfileGrid profiles={profiles} />
    </GridContainer>
  );
}
function ProfileGrid({ profiles }) {
  const arrProfile = [];
  for (let key in profiles) {
    let profile = profiles[key];
    arrProfile.push(
      <CategoryList
        key={key}
        categories={profile}
        title={key}
        profileID={profiles[key][0].fkPerfil}
      />
    );
  }
  return arrProfile;
}
function CategoryItem({ name, id, profileID }) {
  const history = useHistory();
  const [isDeleted, setDelete] = useState(0);
  if (isDeleted) {
    return <></>;
  }
  return (
    <>
      <GridItem xs={10}>
        <p>{name}</p>
      </GridItem>
      <GridItem
        xs={2}
        style={{
          display: "flex",
          floxFlow: "column",
          justifyContent: "center",
        }}
      >
        <FaButton
          icon="edit"
          style={{ color: infoColor }}
          onClick={() => {
            history.push(`/category/${id}`);
          }}
        />
        <ConfirmButton
          comp={FaButton}
          title={`Seguro deseas desvincular la categoria ${name}`}
          icon="times"
          style={{ color: dangerColor }}
          onClick={() => {
            const fetch = new CategoryController();
            fetch.unlink(id, profileID);
            setDelete(1);
          }}
        />
      </GridItem>
    </>
  );
}
function CategoryMenu({ profileID, onSave }) {
  const [open, toggle] = useState(0);
  const [catData, setCat] = useState({});
  return (
    <>
      <SimpleMenu
        items={[
          { title: "Crear Categoria", path: `/category?profile=${profileID}` },
          {
            title: "Vincular Categoria",
            action: () => {
              toggle(1);
            },
          },
        ]}
      >
        <FaButton  icon="plus" onClick={() => {}}>
          Agregar categoria
        </FaButton>
      </SimpleMenu>
      <ConditionalWall condition={open}>
        <GridContainer className="catSelector">
          <GridItem xs={10}>
            <SelectCategory
              onChange={(content) => {
                content.profileID = profileID;
                setCat(content);
              }}
            />
          </GridItem>
          <GridItem xs={2} className="saveContainer">
            <FaButton
              icon="save"
              style={{ color: successColor }}
              onClick={() => {
                const fetch = new CategoryController();
                fetch.link(catData.value, profileID);
                optionalFn(onSave)(catData);
              }}
            />
          </GridItem>
        </GridContainer>
      </ConditionalWall>
    </>
  );
}
function CategoryList({ categories, title, profileID }) {
  const [storedCat, setCat] = useReducer((prev, next) => {
    return [...prev, next];
  }, categories);
  return (
    <GridItem xs={12} s={6} md={3}>
      <PanelContainer
        title={title}
        style={{ width: "90%", margin: "30px auto" }}
        className="profileGrid"
      >
        {storedCat.map((item) => (
          <CategoryItem
          key={`cat-${item.fkPerfil}-${item.fkCategoria}`}
            name={item.categoria}
            id={item.fkCategoria}
            profileID={item.fkPerfil}
          />
        ))}
        <GridItem>
          <CategoryMenu
            profileID={profileID}
            onSave={(values) => {
              setCat({
                categoria: values.label,
                fkCategoria: values.value,
                fkPerfil: values.profileID,
              });
            }}
          />
        </GridItem>
      </PanelContainer>
    </GridItem>
  );
}
