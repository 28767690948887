import React, { useState, useEffect } from "react";
import { optionalFn } from "../core/helpers";
import VisibilitySensor from "react-visibility-sensor";
import { Helpers } from "./../core/helpers";
import { GridContainer, GridItem } from "./../components/Grid/Grid";
import { SimpleSearchInput } from "./../components/CustomInput/SearchInput";
export function List({ page = 1, onLoad, load, by, format }) {
  const [list, setList] = useState([]);
  const [visible, setVisibility] = useState(load);
  const loadList = () => {
    if (!visible) {
      setList([]);
      return;
    }
    by.getter().then((response) => {
      if (response.code >= 300) {
        return false;
      }
      const { current_page, last_page } = response.content;
      optionalFn(onLoad)({ current_page, last_page });
      setList(response.content.data);
    });
  };
  useEffect(loadList, [page, visible]);
  return (
    <VisibilitySensor
      partialVisibility
      onChange={(visibility) => {
        setVisibility(visibility);
      }}
    >
      <div
        style={{
          minHeight: list.length === 0 ? "50px" : "fit-content",
          width: "100%",
        }}
      >
        {list.map(format)}
      </div>
    </VisibilitySensor>
  );
}
export function InfiniteList(props) {
  const [pages, setPages] = useState(0);
  const { defaultList, ...rest } = props;
  const printPages = (total) => {
    const pages = [];
    for (let index = 2; index <= total; index++) {
      pages.push(
        <props.defaultList page={index} {...rest} key={"page-" + index} />
      );
    }
    return pages;
  };
  return (
    <>
      <props.defaultList
        {...rest}
        load
        onLoad={(response) => {
          setPages(response.last_page);
        }}
      />
      {printPages(pages)}
    </>
  );
}
export function LocalSearchLists({ by, format }) {
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState("");
  const helper = new Helpers();
  const loadItems = () => {
    by().then((response) => {
      if (response.code < 300) {
        setItems(response.data.content);
      }
    });
  };
  useEffect(loadItems, []);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <SimpleSearchInput
          onChange={({ target }) => {
            if (target.value === "") {
              setSearch("");
            }
          }}
          onSearch={(value) => {
            setSearch(value);
          }}
        />
      </GridItem>
      {helper.searchInObject(items, search).map(format)}
    </GridContainer>
  );
}
