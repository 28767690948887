// core components/views
import {
  SingleUserView,
  UsersListByPayment,
} from "./../views/Users/UsersContainer";
import { Sandbox } from "./../views/Sandbox/Sandbox";
import { CategoriesGridByProfile } from "./../views/Categories/CategoriesContainer";
import { CategoryForm } from "./../views/Categories/CategoryForm";
import { LazyUsersList } from "./../views/Users/UsersContainer";
import { RegisterUser } from "../views/Users/UsersForm";
import {
  PostsView,
  CategoryPostView,
  LazyPostsByPlan,
} from "../views/Posts/PostsContainer";
import { SingularPost } from "./../views/Posts/SingularPost";
import { Home } from "./../views/Dashboard/Dashboard";
import { ValidatorContainer } from "./../views/Posts/ValidatorPosts";
import { UsersListByStatus } from "./../views/Users/UsersContainer";
import { SectionForm } from "./../views/Sections/SectionForm";
import { UsersListByPlan } from "./../views/Users/UsersContainer";
import { UsersListByDebt } from "./../views/Users/UsersContainer";
import { CuponForm } from "./../views/Cupons/RegisterCupon";
import { CuponList } from "../views/Cupons/CuponViews";
// mis views

export class DashboardRoutes {
  get = () => {
    return this.routes;
  };

  routes = [
    {
      redirect: true,
      path: "/",
      to: "/dashboard",
      navbarName: "Redirect",
    },
    {
      path: "/dashboard",
      sidebarName: "Inicio",
      navbarName: "Inicio",
      icon: "home",
      component: Home,
    },
    {
      path: "/users/payments/",
      hidden: true,
      sidebarName: "Usuarios",
      navbarName: "Usuarios",
      icon: "users",
      component: UsersListByPayment,
    },
    {
      path: "/users/debtors/",
      hidden: true,
      sidebarName: "Usuarios",
      navbarName: "Usuarios",
      icon: "users",
      component: UsersListByDebt,
    },
    {
      path: "/users/sections/:id",
      hidden: true,
      sidebarName: "Usuarios",
      navbarName: "Usuarios",
      icon: "users",
      component: UsersListByPlan,
    },
    {
      path: "/posts/section/:id",
      hidden: true,
      sidebarName: "Anuncios",
      navbarName: "Anuncios",
      icon: "ad",
      component: LazyPostsByPlan,
    },
    {
      path: "/user/register",
      sidebarName: "Crear usuario",
      navbarName: "Crear usuario",
      icon: "users",
      component: RegisterUser,
    },

    {
      path: "/users/status/:id",
      hidden: true,
      sidebarName: "Usuario",
      navbarName: "Usuario",
      icon: "users",
      component: UsersListByStatus,
    },
    {
      path: "/users/edit/:id",
      hidden: true,
      sidebarName: "Usuario",
      navbarName: "Usuario",
      icon: "users",
      component: SingleUserView,
    },
    {
      path: "/users",
      hidden: true,
      sidebarName: "Usuarios",
      navbarName: "Usuarios",
      icon: "users",
      component: LazyUsersList,
    },
    {
      path: "/posts/validate",
      sidebarName: "Anuncios",
      navbarName: "Anuncios",
      icon: "ad",
      hidden: true,
      component: ValidatorContainer,
    },

    {
      path: "/posts/category/:id",
      hidden: true,
      sidebarName: "Anuncios",
      navbarName: "Anuncios",
      icon: "ad",
      component: CategoryPostView,
    },
    {
      path: "/posts/edit/:id",
      hidden: true,
      sidebarName: "Anuncios",
      navbarName: "Anuncios",
      icon: "ad",
      component: SingularPost,
    },
    {
      path: "/posts",
      hidden: true,
      sidebarName: "Anuncios",
      navbarName: "Anuncios",
      icon: "ad",
      component: PostsView,
    },
    {
      path: "/categories",
      sidebarName: "Categorias",
      navbarName: "Categorias",
      icon: "layer-group",
      component: CategoriesGridByProfile,
    },
    {
      path: "/category/:id?",
      sidebarName: "Registro de Categoria",
      navbarName: "Categorias",
      icon: "list",
      component: CategoryForm,
    },
    {
      path: "/sections/:id",
      hidden: true,
      sidebarName: "Planes",
      navbarName: "Planes",
      icon: "list",
      component: SectionForm,
    },
    {
      path: "/cupones/form",
      hidden: true,
      sidebarName: "añadir cupones",
      navbarName: "añadir cupones",
      icon: "dollar-sign",
      component: CuponForm,
    },
    {
      path: "/cupones",
      sidebarName: "cupones",
      navbarName: "cupones",
      icon: "dollar-sign",
      component: CuponList,
    },
    {
      path: "/sandbox",
      hidden: true,
      sidebarName: "sbx",
      navbarName: "sbx",
      icon: "user-cog",
      component: Sandbox,
    },
  ];
}
