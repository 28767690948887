import React from "react";

import { ConfirmButton } from "./../../components/CustomButtons/ConfirmButtom";

import { Image } from "./../../components/Images/Image";
import { GridContainer, GridItem } from "./../../components/Grid/Grid";
import { FaButton } from "./../../components/CustomButtons/FaButton";

import { useCState } from "./../../utils/hooks/simpleHooks";
import {
  infoColor,
  successColor,
  dangerColor,
  warningColor,
} from "assets/jss/material-dashboard-react.jsx";
import { NavLink } from "react-router-dom";
import { UsersController } from "./../../utils/Controller/UsersController";
import { ActionsResponsiveMenu } from "./../../localComponents/SimpleMenu";

export function UserPreview(props) {
  const [user, setUser] = useCState(props);
  const { img, name, plan, perfil, status, id, username } = user;
  return (
    <GridContainer className="userPreview">
      <GridItem xs={2} className="imageContainer">
        <Image type="profile" src={img} />
      </GridItem>
      <GridItem xs={9} s={8}>
        <GridContainer>
          <GridItem xs={12} className="username">
            {name}
          </GridItem>
          <GridItem xs={4} className="subtext">
            {plan}
          </GridItem>
          <GridItem xs={4} className="subtext">
            {perfil}
          </GridItem>
          <GridItem xs={4} className="subtext">
            {status}
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={1} s={2} className="actions">
        <ActionsResponsiveMenu>
          <NavLink to={`/users/edit/${id}`}>
            <FaButton
              title={"Editar"}
              icon="edit"
              style={{ color: infoColor }}
            />
          </NavLink>
          <ConfirmButton
            comp={FaButton}
            title="Activar"
            helpText={`Estas seguro que deseas validar el pago a ${username}`}
            icon="dollar-sign"
            style={{ color: successColor }}
            onClick={() => {
              const fetch = new UsersController();
              fetch.pay(id);
              setUser({ status: "Pagado" });
            }}
          />
          {status === "BANEADO" ? (
            <ConfirmButton
              comp={FaButton}
              title="ReActivar"
              helpText={`Estas seguro que deseas reactivar a ${username}`}
              icon="check"
              style={{ color: successColor }}
              onClick={(ev) => {
                const fetch = new UsersController();
                fetch.unBan(id);
                setUser({ status: "ACTIVO" });
              }}
            />
          ) : (
            <ConfirmButton
              comp={FaButton}
              title="Suspender"
              helpText={`Estas seguro que deseas suspender a ${username}`}
              icon="ban"
              style={{ color: warningColor }}
              onClick={(ev) => {
                const fetch = new UsersController();
                fetch.ban(id);
                setUser({ status: "BANEADO" });
              }}
            />
          )}

          <ConfirmButton
            onClick={(ev) => {
              const fetch = new UsersController();
              fetch.delete(id);
              setUser({ status: "ELIMINADO" });
            }}
            comp={FaButton}
            title="Eliminar"
            helpText={`Estas seguro que deseas ELIMINAR a ${username}`}
            icon="trash"
            style={{ color: dangerColor }}
          />
        </ActionsResponsiveMenu>
      </GridItem>
    </GridContainer>
  );
}
