import React, { useEffect } from "react";

import { ProfileSelector } from "./../Profiles/ProfileSelector";
import { PlanSelector } from "./../Sections/SectionComponents";
import { SimpleInput } from "./../../components/CustomInput/SimpleInput";
import { UsersController } from "./../../utils/Controller/UsersController";
import { useCState } from "./../../utils/hooks/simpleHooks";
import { optionalFn } from "../../core/helpers";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { SimpleImageUploader } from "../../components/UploaderInputs/UploaderInput";
import { useState } from "react";
import { ConditionalWall } from "./../../components/FilterWall/ConditionalWall";

export function UserEditor(content) {
  const sendChange = (values) => {
    const fetch = new UsersController();
    fetch.update(content.id, values);
  };
  return <UserForm {...content} sendChange={sendChange} />;
}
function UserForm({ sendChange, errors, ...rest }) {
  const [state, setState] = useCState({username:"",name:"",paterno:"",materno:"",email:"",telefono:"",whatsapp:"",password:""});
  useEffect(() => {
    setState(rest);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <ProfileSelector
        title={"Perfil"}
        error={errors["fkperfil"]}
        errMsg={errors["fkperfil"]}
        value={state.fkPerfil}
        onChange={(result) => {
          setState({ fkPerfil: result.value ,isAdmin:result.isAdmin});
          optionalFn(sendChange)({ fkPerfil: result.value });
        }}
      />
      <ConditionalWall condition={!state.isAdmin}>
      <PlanSelector
        title={"Plan"}
        error={errors["fkPlan"]}
        errMsg={errors["fkPlan"]}
        value={state.fkPlan}
        onChange={(result) => {
          setState({ fkPlan: result.value });
          optionalFn(sendChange)({ fkPlan: result.value });
        }}
      />
      </ConditionalWall>
      
      <SimpleInput
        required
        title="Username"
        value={state.username}
        errorStatus={errors["username"]}
        errorMessage={errors["username"]}
        onBlur={({ target }) => {
          setState({ username: target.value });
          optionalFn(sendChange)({ username: target.value });
        }}
      />
      <SimpleInput
        required
        title="Nombre"
        value={state.name}
        errorStatus={errors["name"]}
        errorMessage={errors["name"]}
        onBlur={({ target }) => {
          setState({ name: target.value });
          optionalFn(sendChange)({ name: target.value });
        }}
      />
      <SimpleInput
        required
        title="Apellido Paterno"
        value={state.paterno}
        errorStatus={errors["paterno"]}
        errorMessage={errors["paterno"]}
        onBlur={({ target }) => {
          setState({ paterno: target.value });
          optionalFn(sendChange)({ paterno: target.value });
        }}
      />
      <SimpleInput
        required
        title="Apellido Materno"
        value={state.materno}
        errorStatus={errors["materno"]}
        errorMessage={errors["materno"]}
        onBlur={({ target }) => {
          setState({ materno: target.value });
          optionalFn(sendChange)({ materno: target.value });
        }}
      />
      <SimpleInput
        required
        title="Correo"
        type="email"
        value={state.email}
        errorStatus={errors["email"]}
        errorMessage={errors["email"]}
        onBlur={({ target }) => {
          setState({ email: target.value });
          optionalFn(sendChange)({ email: target.value });
        }}
      />
      <SimpleInput
        required
        title="Telefono"
        type="tel"
        value={state.telefono}
        errorStatus={errors["telefono"]}
        errorMessage={errors["telefono"]}
        onBlur={({ target }) => {
          setState({ telefono: target.value });
          optionalFn(sendChange)({ telefono: target.value });
        }}
      />
      <SimpleInput
        required
        title="Celular"
        type="tel"
        value={state.whatsapp}
        errorStatus={errors["whatsapp"]}
        errorMessage={errors["whatsapp"]}
        onBlur={({ target }) => {
          setState({ whatsapp: target.value });
          optionalFn(sendChange)({ whatsapp: target.value });
        }}
      />
      <SimpleInput
        required
        title="Contraseña"
        type="password"
        errorStatus={errors["password"]}
        errorMessage={errors["password"]}
        value={state.password}
        onBlur={({ target }) => {
          setState({ password: target.value });
          optionalFn(sendChange)({ password: target.value });
        }}
      />
    </>
  );
}
export function RegisterUser() {
  const [state, setState] = useCState({});
  const [errors, setError] = useState({});
  const [loading, setLoading] = useState(0);
  const history = useHistory();
  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        setLoading(1);
        const fetch = new UsersController();
        let customState = state;
        customState.correo = state.email;
        customState.fkgenero = 1;
        customState.fkperfil = state.fkPerfil;
        customState.fkplan = state.fkPlan || 4;
        customState.nombre = state.name;
        customState.telefono = customState.telefono.replace(/[^0-9.]/g, "");
        customState.whatsapp = customState.whatsapp.replace(/[^0-9.]/g, "");
        fetch.post(customState).then((response) => {
          setLoading(0);
          if (response.code > 300) {
            setError(response.message);
          } else {
            history.push("/users");
          }
        });
      }}
    >
      <ProfileImage
        error={errors["picture_path"]}
        src={state.picture_path}
        onChange={(target) => {
          setState({ imagen: target.files[0] });
        }}
      />
      <UserForm sendChange={setState} errors={errors} />
      <ConditionalWall condition={!loading} or={"Cargando..."}>
        <Button type={"submit"}>Crear Usuario</Button>
      </ConditionalWall>
    </form>
  );
}
export function ProfileImage({ src, error, onChange }) {
  return (
    <>
      <SimpleImageUploader src={src} type={"bigProfile"} onChange={onChange} />
      <ConditionalWall condition={error}>
        <div className="error">{error || "Parece que hay un error aqui."}</div>
      </ConditionalWall>
    </>
  );
}
