import React, { useState } from "react";
import { ScreenRangeContainer } from "./Blocks";
import { FaButton } from "./../components/CustomButtons/FaButton";
import { Menu } from "@material-ui/core";
export function ActionsResponsiveMenu({ children }) {
  const [anchorEl, setAnchor] = useState();
  return (
    <>
      <ScreenRangeContainer max={1000}>
        <FaButton
          icon="ellipsis-v"
          onClick={({ currentTarget }) => {
            setAnchor(currentTarget);
          }}
        />
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchor(null);
          }}
          className="menu"
        >
          {children}
        </Menu>
      </ScreenRangeContainer>
      <ScreenRangeContainer min={1001}>{children}</ScreenRangeContainer>
    </>
  );
}
