import React, { useState } from "react";
import { ConditionalModal } from "./../CustomModal/ConditionalModal";
import { optionalFn } from "../../core/helpers";
import "./scss/confirm.scss";
export function ConfirmButton(props) {
  const { comp, helpText, title, onClick, children, ...rest } = props;
  const [alert, setAlert] = useState(0);
  //console.log(comp);
  return (
    <>
      <ConditionalModal
        className="confirmButton"
        title={title}
        open={Boolean(alert)}
        onSubmit={(response) => {
          if (response) {
            optionalFn(onClick)();
          }
          setAlert(0);
        }}
      >
        <p>{helpText}</p>
      </ConditionalModal>
      <props.comp
        onClick={() => {
          setAlert(1);
        }}
        title={title}
        {...rest}
      >
        {children}
      </props.comp>
    </>
  );
}
