import { useEffect, useReducer } from "react";

export function useCState(initState = {}) {
  return useReducer((prev, next) => ({ ...prev, ...next }), initState);
}

export function useQuery() {
  let search = window.location.search;
  let url = new URLSearchParams(search);
  let querys = {};
  for (let pair of url.entries()) {
    let key = pair[0];
    let value = pair[1];
    querys[key] = value;
  }
  return querys;
}
//https://atomizedobjects.com/blog/react/add-event-listener-react-hooks/
export default function useEvent(event, handler) {
  const load = () => {
    // initiate the event handler
    window.addEventListener(event, handler);
    return function cleanup() {
      window.removeEventListener(event, handler);
    };
  };
  useEffect(load, []);
}
