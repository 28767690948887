import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem } from "@material-ui/core";
import { optionalFn } from "../../core/helpers";
export function SimpleMenu({ children, items }) {
  const history = useHistory();
  const [anchorEl, setAnchor] = useState(null);
  return (
    <>
      <div
        onClick={(ev) => {
          setAnchor(ev.currentTarget);
        }}
      >
        {children}
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchor(null);
        }}
      >
        {items.map((item, key) => (
          <MenuItem
            key={key}
            onClick={() => {
              setAnchor(null);
              if (item.path) history.push(item.path);
              else {
                optionalFn(item.action)();
              }
            }}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
