import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { optionalFn } from "../../core/helpers";
import { UsersController } from "../../utils/Controller/UsersController";
import { GridItem } from "../../components/Grid/Grid";
import { GridContainer } from "./../../components/Grid/Grid";
import { useCState } from "./../../utils/hooks/simpleHooks";
import { Button } from "@material-ui/core";
import { SimpleInput } from "../../components/CustomInput/SimpleInput";
import "./scss/account.scss";
import { ProfileSelector } from "./../Profiles/ProfileSelector";
import { PlanSelector } from "./../Sections/SectionComponents";
import { InfoData } from "../../components/CustomInput/toggleInput";

/**
 * Retorna una lista con la informacion de usuario.
 * @returns component
 */
export function Account({ id }) {
  const [user, setUser] = useState({});
  const {
    username,
    name,
    email,
    paterno,
    materno,
    telefono,
    whatsapp,
    perfil,
    fkPerfil,
    plan,
    fkPlan,
  } = user;
  const history = useHistory();
  const loadUser = () => {
    let fetch = new UsersController();
    fetch.show(id).then((response) => {
      if (response.code < 300) setUser(response.data.content[0]);
      else {
        history.push("/home");
      }
    });
  };
  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <GridContainer className="account">
      <GridItem xs={12}>
        <ProfileData
          id={id}
          title={"Perfil"}
          value={{ label: perfil, value: fkPerfil }}
        />
      </GridItem>
      <GridItem xs={12}>
        <PlanData
          id={id}
          title={"Plan"}
          value={{ label: plan, value: fkPlan }}
        />
      </GridItem>
      <GridItem xs={12}>
        <GenericData id={id} title={"Nombre de usuario"} value={{ username }} />
      </GridItem>

      <GridItem xs={12}>
        <GenericData id={id} title={"Correo"} value={{ email }} />
      </GridItem>
      <GridItem xs={12}>
        <GenericData
          id={id}
          title={"Contraseña"}
          type="password"
          value={{ password: "**********" }}
        />
      </GridItem>

      <GridItem xs={12}>
        <NameData
          id={id}
          title={"Nombre"}
          values={{ name, paterno, materno }}
        />
      </GridItem>
      <GridItem xs={12}>
        <GenericData
          id={id}
          title={"Teléfono"}
          type="tel"
          value={{ telefono }}
        />
      </GridItem>
      <GridItem xs={12}>
        <GenericData
          id={id}
          title={"WhatsApp"}
          type="tel"
          value={{ whatsapp }}
        />
      </GridItem>
    </GridContainer>
  );
}
function NameData({ id, title, values }) {
  const [state, setState] = useCState(values);
  const { name, paterno, materno } = state;
  const [open, toggle] = useState(0);
  useEffect(() => {
    setState(values);
    // eslint-disable-next-line
  }, [values]);
  if (open) {
    return (
      <NameForm
        id={id}
        values={state}
        onSave={(fullname) => {
          toggle(0);
          fullname.name = fullname.nombre;
          setState(fullname);
        }}
        onClose={() => {
          toggle(0);
        }}
      />
    );
  }
  return (
    <InfoData
      title={title}
      value={`${name} ${paterno} ${materno}`}
      onClick={() => {
        toggle(1);
      }}
    />
  );
}
function NameForm({ id, values, onSave, onClose }) {
  values.nombre = values.name;
  const [state, setState] = useCState(values);
  const { nombre, paterno, materno } = state;
  return (
    <GridContainer>
      <GridItem xs={12}>
        <SimpleInput
          title={"Nombre"}
          value={nombre}
          onChange={({ target }) => {
            setState({ nombre: target.value });
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <SimpleInput
          onChange={({ target }) => {
            setState({ paterno: target.value });
          }}
          title={"Apellido Paterno"}
          value={paterno}
        />
      </GridItem>
      <GridItem xs={12}>
        <SimpleInput
          onChange={({ target }) => {
            setState({ materno: target.value });
          }}
          title={"Apellido Materno"}
          value={materno}
        />
      </GridItem>
      <GridItem xs={6}>
        <Button
          onClick={() => {
            optionalFn(onSave)(state);
            let user = new UsersController();
            user.update(id, state);
          }}
        >
          Guardar
        </Button>
      </GridItem>
      <GridItem xs={6}>
        <Button
          onClick={() => {
            optionalFn(onClose)();
          }}
        >
          Cancelar
        </Button>
      </GridItem>
    </GridContainer>
  );
}
function GenericData({ id, title, type, value, ...rest }) {
  const [open, toggle] = useState(0);
  const [val, setValue] = useState(value);
  useEffect(() => {
    setValue(value);
  }, [value]);

  let content = Object.values(val)[0];
  if (type === "password") {
    content = "**********";
  }
  if (open) {
    return (
      <GenericForm
        value={value}
        title={title}
        type={type}
        id={id}
        {...rest}
        onSave={(data) => {
          toggle(0);
          setValue(data);
        }}
        onClose={() => {
          toggle(0);
        }}
      />
    );
  }
  return (
    <InfoData
      title={title}
      value={content}
      onClick={() => {
        toggle(1);
      }}
    />
  );
}
function GenericForm({
  id,
  title,
  type = "text",
  value,
  onSave,
  onClose,
  ...rest
}) {
  const name = Object.keys(value)[0];
  let data = {};
  data[name] = type === "password" ? "" : value[name];
  const [val, setValue] = useState(data);
  const content = Object.values(val)[0];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <SimpleInput
          type={type}
          title={title}
          onChange={({ target }) => {
            let data = {};
            data[name] = target.value;
            setValue(data);
          }}
          value={content}
        />
      </GridItem>
      <GridItem xs={6}>
        <Button
          onClick={() => {
            optionalFn(onSave)(val);
            const user = new UsersController();
            user.update(id, val);
          }}
        >
          Guardar
        </Button>
      </GridItem>
      <GridItem xs={6}>
        <Button
          onClick={() => {
            optionalFn(onClose)();
          }}
        >
          Cancelar
        </Button>
      </GridItem>
    </GridContainer>
  );
}
function ProfileData({ id, title, value, ...rest }) {
  const [open, toggle] = useState(0);
  const [val, setValue] = useState(value);
  useEffect(() => {
    setValue(value);
  }, [value]);
  if (open) {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <ProfileSelector
            value={val.value}
            title={title}
            id={id}
            onChange={(data) => {
              setValue(data);
            }}
            {...rest}
          />
        </GridItem>
        <GridItem xs={6}>
          <Button
            onClick={() => {
              const user = new UsersController();
              user.update(id, { fkperfil: val.value });
              toggle(0);
            }}
          >
            Guardar
          </Button>
        </GridItem>
        <GridItem xs={6}>
          <Button
            onClick={() => {
              toggle(0);
            }}
          >
            Cancelar
          </Button>
        </GridItem>
      </GridContainer>
    );
  }
  return (
    <InfoData
      title={title}
      value={val.label}
      onClick={() => {
        toggle(1);
      }}
    />
  );
}
function PlanData({ id, title, value, ...rest }) {
  const [open, toggle] = useState(0);
  const [val, setValue] = useState(value);
  useEffect(() => {
    setValue(value);
  }, [value]);
  if (open) {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <PlanSelector
            value={val.value}
            title={title}
            id={id}
            onChange={(data) => {
              setValue(data);
            }}
            {...rest}
          />
        </GridItem>
        <GridItem xs={6}>
          <Button
            onClick={() => {
              const user = new UsersController();
              user.update(id, { fkplan: val.value });
              toggle(0);
            }}
          >
            Guardar
          </Button>
        </GridItem>
        <GridItem xs={6}>
          <Button
            onClick={() => {
              toggle(0);
            }}
          >
            Cancelar
          </Button>
        </GridItem>
      </GridContainer>
    );
  }
  return (
    <InfoData
      title={title}
      value={val.label}
      onClick={() => {
        toggle(1);
      }}
    />
  );
}
